import React from 'react';
import { Helmet } from 'react-helmet';

const Emergencydentist = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Emergency Dentist In Miranda | Dr Olga Palma</title>
      <meta name="description" content="We have dentists available for any sudden dental emergency in Miranda such as knocking out a tooth. Call us or visit our website for more information." />
      <link rel="alternate" href="https://drolgap.com.au/emergency-dentist" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au/emergency-dentist" />

    </Helmet>

     <h1>Emergency Dentist Services In Miranda </h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
		<p>Everyone understands the importance of maintaining good oral hygiene. Not only do you keep your teeth intact but you also save a huge chunk of money by doing so. As dental treatments are too costly at times. But what if you face an emergency such as broken tooth or gum bleeding or severe toothache? You can’t wait for a few days before visiting your dentist. In such cases, you would require an emergency dentist. Dr. Olga Palma is one of the finest emergency dentists in Miranda.</p>
		
<p>Dr. Olga has undertaken countless number of emergency dental cases in her illustrious career. She and her team have successfully dealt with these cases with great ease, she uses the latest technology to treat every emergency dental problem at her clinic. Indeed, you will not find a better emergency dentist in Miranda than Dr. Olga Palma.</p>

<h2><strong>Comprehensive Emergency Dental Services</strong></h2>
<p>Emergencies are of different types. at our clinic, you will get the treatment for each one of those emergencies. Over the years, we have registered many emergency dental problems at our clinic. we would like to share how we treat these emergencies below. </p>
<p><strong>Broken Tooth</strong>: Traumatic injuries are unavoidable. Especially when you work at a place where getting a blow to your face is common. Thus, one or two of your teeth may be broken. We implement various tooth-saving techniques at our clinic.</p>

<p><strong>Severe Toothache</strong>: Have you ever experienced toothache in the middle of the night? Many people in Australia do experience this. At Dr. Olga Palma clinic, we have the perfect dental care for your severe toothache. We will instantly work on the underlying causes of your toothache.
</p>

<p><strong>Dental Abscess: </strong>You may see an abscess or infection at the base of your tooth. In such cases, Dr. Olga Palma and her team treat the underlying issues such as gum disease or cavities. Similarly, in some cases, an abscess may be a result of a broken tooth as well. </p>
<p>we also give dental care for various other emergencies such as - </p>
<ul>
<li>Metal Taste</li>
<li>Numbing of Tooth</li>
<li>Denture Repair</li>
<li>Swollen Jaw</li>
<li>Teeth Extraction&nbsp;</li>
</ul>
<p>One small mistake and it may cost you dearly. We make sure to use the latest technology and equipment when we are dealing with an emergency.</p>
<h2><strong>Easy Payment Through AfterPay</strong></h2>
<p>Most people avoid emergency dental care due to unreasonable fees quoted by their dentist. But at Dr. Olga Palma, you never have to worry about such things for your emergency treatment. We have always given the most affordable rates for our emergency services. To ease your payment burden, we also accept AfterPay payment. </p>


        </article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="emergency dentist miranda" src="https://res.cloudinary.com/drolgap/image/upload/v1611039042/website/services/Emergency_Dentist_hh7ifi.jpg" />
      </div>
    </div>
  </div>
}



export default Emergencydentist;
