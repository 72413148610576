import React from 'react';
import { Helmet } from 'react-helmet';

const Dentures = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Dentures & Denture Repair in Miranda | Dr Olga Palma</title>
      <meta name="description" content="Dentures are a non-invasive way to replace missing teeth. You can opt for full or partial dentures in Miranda. Visit our website for info." />
      <link rel="alternate" href="https://drolgap.com.au/dentures" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au/dentures" />

    </Helmet>

     <h1>Dentures And Denture Repairs in Miranda </h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
		<p>Missing a few teeth can be a big hurdle in your day to day life. You cannot smile, chew food or even speak properly. And such things will only worsen your quality of life if left untreated. So, what is the most economical yet highly effective dental treatment for missing teeth? And the answer is dentures. But what is the procedure for this treatment?  Let us answer these questions for you. </p>
<h2><strong>Dentures and Its Uses</strong>&nbsp;</h2>
<p>Basically, dentures are artificial teeth. Your dentist will custom make these dentures to replace the missing teeth. Dentures will give you a more natural smile. In some people, dentures may also help combat sagging of the cheeks and facial muscles, and may also enhance your eating, speaking, and communication abilities.
</p>

<h2><strong>Types Of Dentures</strong>&nbsp;</h2>
<p>Dentures are the least invasive and most affordable teeth replacement option. And there are two types of Dentures -</p>
<ul>
<li>Partial dentures are used when one or more natural teeth remain in the upper or lower jaw. Not only does a partial denture fill in the spaces created by missing teeth, but it also prevents other teeth from changing position.</li>
</ul>
<ul>
<li>Full dentures are made to replace all of the teeth in your upper jaw, lower jaw, or both. When dentures no longer fit well or are broken, the correct action is to seek follow-up care – we will be able to reline or repair the denture for you.</li>
</ul>


<h2><strong>We Use Cutting Edge Technology For Everything</strong></h2>
<p>Today, the dental care industry utilises the latest technology and systems to provide efficient, reliable and quick treatment. That is exactly why you can sit and relax on your chair while she and her team works their magic. You will have no complaints whatsoever once she is done fitting dentures onto your missing teeth.</p>

<h2><strong>Affordable Dental Treatment In Miranda</strong></h2>
<p>At times, dental care can really put a dent to your savings. And no one likes to see this happening when they just wanted to get good dentures. But many dental clinics charge unreasonable fees for this treatment. However, at Dr. Olga Palma, you will never be charged exorbitant fees. Furthemore, you can use AfterPay to make your payment at our clinic.</p>
</article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="dentures miranda" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568564034/website/services/Depositphotos_15554251_ds-min_diqmyg.jpg" />
      </div>
    </div>
  </div>
}



export default Dentures;
