import About from 'containers/About/About';
import AfterPay from 'containers/AfterPay/AfterPay';
import Contact from 'containers/Contact/Contact';
import Home from 'containers/Home/Home';
import Medicare from 'containers/Medicare/Medicare';
import Services from 'containers/Services/Services';
import Veneers from 'containers/Veneers/Veneers';
import Cosmeticdentistry from 'containers/Cosmeticdentistry/Cosmeticdentistry';
import Crownsbridges from 'containers/Crownsbridges/Crownsbridges';
import Dentures from 'containers/Dentures/Dentures';
import Rootcanaltreatment from 'containers/Rootcanaltreatment/Rootcanaltreatment';
import Teethwhitening from 'containers/Teethwhitening/Teethwhitening';
import Toothextraction from 'containers/Toothextraction/Toothextraction';
import Amalgamremoval from 'containers/Amalgamremoval/Amalgamremoval';
import Oralhygiene from 'containers/Oralhygiene/Oralhygiene';
import Dentalimplants from 'containers/Dentalimplants/Dentalimplants';
import Childrensdental from 'containers/Childrensdental/Childrensdental';
import Orthodontics from 'containers/Orthodontics/Orthodontics';
import Snoringandsleepapnoea from 'containers/Snoringandsleepapnoea/Snoringandsleepapnoea';
import Tmjandchronicpain from 'containers/Tmjandchronicpain/Tmjandchronicpain';
//import Errorpage from 'containers/Errorpage/Errorpage';
import Mouthguards from 'containers/Mouthguards/Mouthguards';
import Emergencydentist from 'containers/Emergencydentist/Emergencydentist';
import Cosmeticdentistsutherlandshire from 'containers/Cosmeticdentistsutherlandshire/Cosmeticdentistsutherlandshire';
import Crownsandbridgessutherlandshire from 'containers/Crownsandbridgessutherlandshire/Crownsandbridgessutherlandshire';
import Dentistcronulla from 'containers/Dentistcronulla/Dentistcronulla';
import Dentistsutherlandshire from 'containers/Dentistsutherlandshire/Dentistsutherlandshire';
import Dentistwoollahra from 'containers/Dentistwoollahra/Dentistwoollahra';
import Denturessutherlandshire from 'containers/Denturessutherlandshire/Denturessutherlandshire';
import Emergencydentistcaringbah from 'containers/Emergencydentistcaringbah/Emergencydentistcaringbah';
import Emergencydentistsutherlandshire from 'containers/Emergencydentistsutherlandshire/Emergencydentistsutherlandshire';
import Rootcanaltreatmentsutherlandshire from 'containers/Rootcanaltreatmentsutherlandshire/Rootcanaltreatmentsutherlandshire';
import Teethwhiteningcaringbah from 'containers/Teethwhiteningcaringbah/Teethwhiteningcaringbah';
import Teethwhiteningmenai from 'containers/Teethwhiteningmenai/Teethwhiteningmenai';
import Teethwhiteningsutherlandshire from 'containers/Teethwhiteningsutherlandshire/Teethwhiteningsutherlandshire';
import Servicearea from 'containers/Servicearea/Servicearea';
import Clearaligners from 'containers/Clearaligners/Clearaligners';
import CosmeticInjections from 'containers/CosmeticInjections/CosmeticInjections';

export const routes = [  
  {
    component: About,
    exact: true,
    id: 'about-01',
    path: '/about'
  }, {
    component: AfterPay,
    exact: true,
    id: 'afterpay-01',
    path: '/afterpay'
  }, {
    component: Contact,
    exact: true,
    id: 'contact-01',
    path: '/contact'
  }, {
    component: Home,
    exact: true,
    id: 'home-01',
    path: '/'
  }, {
    component: Medicare,
    exact: true,
    id: 'medicare-01',
    path: '/medicare'
  }, {
    component: Veneers,
    exact: true,
    id: 'veneers-01',
    path: '/veneers'
  }, {
    component: Services,
    exact: true,
    id: 'services-01',
    path: '/services'
  }, {
    component: Cosmeticdentistry,
    exact: true,
    id: 'Cosmeticdentistry-01',
    path: '/cosmetic-dentistry'
  }, {
    component: Crownsbridges,
    exact: true,
    id: 'Crownsbridges-01',
    path: '/crowns-bridges'
  }, {
    component: Dentures,
    exact: true,
    id: 'Dentures-01',
    path: '/dentures'
  }, {
    component: Rootcanaltreatment,
    exact: true,
    id: 'Rootcanaltreatment-01',
    path: '/root-canal-treatment'
  }, {
    component: Teethwhitening,
    exact: true,
    id: 'Teethwhitening-01',
    path: '/teeth-whitening'
  }, {
    component: Toothextraction,
    exact: true,
    id: 'Toothextraction-01',
    path: '/tooth-extraction'
  }, {
    component: Amalgamremoval,
    exact: true,
    id: 'Amalgamremoval-01',
    path: '/amalgam-removal'
  }, {
    component: Oralhygiene,
    exact: true,
    id: 'Oralhygiene-01',
    path: '/oral-hygiene'
  }, {
    component: Dentalimplants,
    exact: true,
    id: 'Dentalimplants-01',
    path: '/dental-implants'
  }, {
    component: Childrensdental,
    exact: true,
    id: 'Childrensdental-01',
    path: '/childrens-dental'
  }, {
    component: Orthodontics,
    exact: true,
    id: 'Orthodontics-01',
    path: '/orthodontics'
  }, {
    component: Snoringandsleepapnoea,
    exact: true,
    id: 'Snoringandsleepapnoea-01',
    path: '/snoring-and-sleep-apnoea'
  }, {
    component: Tmjandchronicpain,
    exact: true,
    id: 'Tmjandchronicpain-01',
    path: '/tmj-and-chronic-pain'
  }, {
    // component: Errorpage,
    // exact: true,
    // id: 'Errorpage-01',
    // path: '/errorpage'
  }, {
    component: Mouthguards,
    exact: true,
    id: 'Mouthguards-01',
    path: '/mouthguards'
  }, {
    component: Emergencydentist,
    exact: true,
    id: 'Emergencydentist-01',
    path: '/emergency-dentist'
  }, {
    component: Cosmeticdentistsutherlandshire,
    exact: true,
    id: 'Cosmeticdentistsutherlandshire-01',
    path: '/cosmetic-dentist-sutherland-shire'
  }, {
    component: Crownsandbridgessutherlandshire,
    exact: true,
    id: 'Crownsandbridgessutherlandshire-01',
    path: '/crowns-and-bridges-sutherland-shire'
  }, {
    component: Dentistcronulla,
    exact: true,
    id: 'Dentistcronulla-01',
    path: '/dentist-cronulla'
  }, {
    component: Dentistsutherlandshire,
    exact: true,
    id: 'Dentistsutherlandshire-01',
    path: '/dentist-sutherland-shire'
  }, {
    component: Dentistwoollahra,
    exact: true,
    id: 'Dentistwoollahra-01',
    path: '/dentist-woollahra'
  }, {
    component: Denturessutherlandshire,
    exact: true,
    id: 'Denturessutherlandshire-01',
    path: '/dentures-sutherland-shire'
  }, {
    component: Emergencydentistcaringbah,
    exact: true,
    id: 'Emergencydentistcaringbah-01',
    path: '/emergency-dentist-caringbah'
  }, {
    component: Emergencydentistsutherlandshire,
    exact: true,
    id: 'Emergencydentistsutherlandshire-01',
    path: '/emergency-dentist-sutherland-shire'
  }, {
    component: Rootcanaltreatmentsutherlandshire,
    exact: true,
    id: 'Rootcanaltreatmentsutherlandshire-01',
    path: '/root-canal-treatment-sutherland-shire'
  }, {
    component: Teethwhiteningcaringbah,
    exact: true,
    id: 'Teethwhiteningcaringbah-01',
    path: '/teeth-whitening-caringbah'
  }, {
    component: Teethwhiteningmenai,
    exact: true,
    id: 'Teethwhiteningmenai-01',
    path: '/teeth-whitening-menai'
  }, {
    component: Teethwhiteningsutherlandshire,
    exact: true,
    id: 'Teethwhiteningsutherlandshire-01',
    path: '/teeth-whitening-sutherland-shire'
  }, {
    component: Servicearea,
    exact: true,
    id: 'Servicearea-01',
    path: '/service-area'
  }, {
    component: Clearaligners,
    exact: true,
    id: 'Clearaligners-01',
    path: '/clear-aligners'
  },
  {
    component: CosmeticInjections,
    exact: true,
    id: 'CosmeticInjections-01',
    path: '/cosmetic-injections'
  }, {
    component: Services,
    exact: true,
    id: 'test-01',
    path: '/services/test'
  }
]
