import React from 'react';
import { Helmet } from 'react-helmet';

const Toothextraction = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Tooth Extraction in Miranda, Sydney | Teeth Extraction Miranda</title>
      <meta name="description" content="Dr. Olga Palma's Clinic Offers Advanced Wisdom Tooth Removal & Painless Teeth Extraction Treatment. Visit us in Miranda and improve your oral hygiene regime." />
      <link rel="alternate" href="https://drolgap.com.au/tooth-extraction" hrefLang="en-AU" />
      <link rel="canonical" href="https://drolgap.com.au/tooth-extraction" />

    </Helmet>

    <h1>Tooth Extraction in Miranda, Sydney </h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
          <p>There are many instances where tooth extraction becomes necessary. Sometimes a tooth may need to be removed due to decay, extensive tooth cracks, disease, or poor positioning and functionality. We only perform extractions when it is absolutely required due to a poor prognosis of restorative treatments. Tooth extractions can be completed in a dental chair using a local anaesthetic.</p>

          <p>Similarly, your dentist may advise you to remove a wisdom tooth so tooth extraction is indeed a very useful dental treatment for both children as well adults as in the country. So, where should you go for tooth extraction in Miranda? We believe we have the ideal dental clinic for this treatment. </p>
          <p>Once a tooth has been removed, we will advise you of the available options for replacing the lost tooth such as implants, fixed bridges, or dentures.</p>


          <h2><strong>Wisdom Teeth Removal</strong></h2>
          <p>Wisdom teeth usually come in between 17 to 25 years of age. Sometimes when the wisdom tooth erupts, it may come out at an awkward angle that pushes against the other teeth and causes pain and swelling in the gum and jaw area. They may also become impacted and lead to toothache and create an environment for gum diseases and tooth decay.</p>
          <p>In many cases, the extraction of wisdom teeth will avoid further complications. Wisdom teeth removal is a relatively simple and easy procedure if performed by a trained and experienced dentist. If we determine that the extraction will be particularly challenging, you will be referred to an oral surgeon.</p>

          <h2><strong>Great Payment Options</strong></h2>
          <p>At other dental clinics, you will have to pay an outrageous amount of money for your tooth extraction. But at Dr. Olga Palma clinic, you will never complain about this. We do understand that this treatment could put a lot of financial strain on our customers. And thus, we have always provided the most affordable prices for tooth extraction.
          And to further help you down the line, we allow payments through AfterPay as well.</p>

        </article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="tooth extraction miranda" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568564038/website/services/Depositphotos_39863413_ds-min_d3ciwx.jpg" />
      </div>
    </div>
  </div>
}



export default Toothextraction;
