import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  return <div>
    <nav className="hidden md:block">
      <ul className="flex flex items-center justify-between flex-wrap md:border-b-2 md:border-teal-500">      
        <li>
          <NavLink className="inline-block text-blue-500 hover:bg-teal-700 hover:text-white border-white hover:border-teal-700 border-l border-t border-r rounded-t" activeClassName="navActive" to="/about">About</NavLink>
        </li>
        <li>
          <NavLink className="inline-block text-blue-500 hover:bg-teal-700 hover:text-white border-white hover:border-teal-700 hover:border-l border-t border-r rounded-t" activeClassName="navActive" to="/services">Services</NavLink>
        </li>
        <li>
          <NavLink className="inline-block text-blue-500 hover:bg-teal-700 hover:text-white border-white hover:border-teal-700 border-l border-t border-r rounded-t" activeClassName="navActive" to="/medicare">Medicare (CDBS)</NavLink>
        </li>
        <li>
          <NavLink className="inline-block text-blue-500 hover:bg-teal-700 hover:text-white border-white hover:border-teal-700 border-l border-t border-r rounded-t" activeClassName="navActive" to="/afterpay">AfterPay</NavLink>
        </li>
          <li>
          <NavLink className="inline-block text-blue-500 hover:bg-teal-700 hover:text-white border-white hover:border-teal-700 border-l border-t border-r rounded-t" activeClassName="navActive" to="/contact">Contact Us</NavLink>
        </li>
      </ul>
    </nav>
    <nav className="block md:hidden">
      <ul className="flex flex items-center justify-between flex-wrap md:border-b-2 md:border-teal-500">      
        <li className="block w-full">
          <NavLink className="block bg-teal-500 text-white hover:bg-teal-700 hover:text-white hover:border-teal-700" activeClassName="navActive" to="/about">About</NavLink>
        </li>
        <li className="block md:inline-block w-full md:w-auto">
          <NavLink className="block bg-teal-500 text-white hover:bg-teal-700 hover:text-white hover:border-teal-700" activeClassName="navActive" to="/services">Services</NavLink>
        </li>
        <li className="block md:inline-block w-full md:w-auto">
          <NavLink className="block bg-teal-500 text-white hover:bg-teal-700 hover:text-white hover:border-teal-700" activeClassName="navActive" to="/medicare">Medicare (CDBS)</NavLink>
        </li>
        <li className="block md:inline-block w-full md:w-auto">
          <NavLink className="block bg-teal-500 text-white hover:bg-teal-700 hover:text-white hover:border-teal-700" activeClassName="navActive" to="/afterpay">AfterPay</NavLink>
        </li>
        <li className="block md:inline-block w-full md:w-auto">
          <NavLink className="block bg-teal-500 text-white hover:bg-teal-700 hover:text-white hover:border-teal-700" activeClassName="navActive" to="/contact">Contact Us</NavLink>
        </li>
      </ul>
    </nav>
  </div>
}

export default Navbar;
