import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Public from 'containers/Public/Public';

const App = () => {
  return <BrowserRouter>
    <Switch>
      <Route path="/" component={Public} />
      <Route component={Public} />
    </Switch>
  </BrowserRouter>
}

export default App;