import React from 'react';
import { render } from 'react-dom';

import * as serviceWorker from './serviceWorker';

import App from './App';

/** Custom */
import './assets/fontawesome/fontawesome';
import './assets/fontawesome/regular';
import './assets/styles/tailwind.css';

const target = document.getElementById('root');

render(<App />, target);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();