import React from 'react';
import { Helmet } from 'react-helmet';

const Oralhygiene = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Oral Hygiene | Dr Olga Palma</title>
      <meta name="description" content="Keep your mouth clean & free of disease with oral hygiene services from Dr Olga Palma. Talk with us on 0416 966 839 to know more. " />
      <link rel="alternate" href="https://drolgap.com.au/Oral-hygiene" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au/Oral-hygiene" />

    </Helmet>

     <h1>Oral Hygiene</h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
          <p>While it is important to practice good dental hygiene at home, you must also regularly come in for dental cleaning and check-up.</p><p>We recommend a dental visit and cleaning once every 6 months. We clean your teeth using special instruments that completely remove plaque from both the teeth and underneath the gumline. After the cleaning is done, your teeth are polished to make them look and feel great. Polishing also makes the surface of the teeth smooth so plaque is less likely to accumulate.</p><p>During your dental consultation, the dentist will conduct a comprehensive oral examination which includes: checking for cavities, assessing the condition of your existing restorations, and evaluating the condition of the gums to determine whether you are at risk of gum disease.</p>
          
        </article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="Dr Olga Palma" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568563994/website/services/Depositphotos_11338283_ds_1_-min_oz356v.jpg" />
		 <img alt="Dr Olga Palma" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568563999/website/services/Depositphotos_22068257_ds-min_ukuaeu.jpg" /><br/>
      </div>
    </div>
  </div>
}



export default Oralhygiene;
