import React from 'react';
import { Helmet } from 'react-helmet';

const Amalgamremoval = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Amalgam Removal | Dr Olga Palma</title>
      <meta name="description" content="Dr Olga Palma dental clinic offers best amalgam removals from expert dentists. Book an appointment today on our website. Call us on 0416 966 839 to know more. " />
      <link rel="alternate" href="https://drolgap.com.au/amalgam-removal" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au/amalgam-removal" />

    </Helmet>

     <h1>Amalgam Removal</h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
        <p>Mercury was generally considered safe in the past when used as part of a filling, though in recent years sophisticated testing has changed this view and made it a controversial topic.</p><p>White fillings, known as composite fillings, are a modern alternative to the amalgam or "silver" fillings. White fillings are made of a resin and glass mixture and can be coloured to match your teeth perfectly, so no-one will know they are there.</p><p>We follow a careful protocol for the safe removal of amalgams: a sealed rubber material known as a rubber dam or dental dam is placed over the teeth to prevent any excess mercury being absorbed or ingested by the body, and a high-volume suction process rapidly removes the amalgam particles as the amalgam filling is removed. Mercury vapour is avoided by using a special technique of removing small amalgam chunks.</p>
       </article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="Dr Olga Palma" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568563984/website/services/IMG_3847-min_z7560y.jpg" />
      </div>
    </div>
  </div>
}



export default Amalgamremoval;
