import React from 'react';
import { Helmet } from 'react-helmet';

const Teethwhiteningmenai = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Teeth Whitening Service In Menai | Dr Olga Palma</title>
      <meta name="description" content="Teeth whitening is convenient as it makes your teeth whiter and more shiny. Boost your confidence. Book an appointment with us in Menai for the perfect smile!." />
      <link rel="alternate" href="https://drolgap.com.au/teeth-whitening-menai" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au/teeth-whitening-menai" />

    </Helmet>

     <h1>Teeth Whitening Service In Menai</h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
	<p>Nowadays, you can easily get rid of yellow or stained teeth. There are many teeth whitening solutions available in Australia. But you should always visit the best dentist for your teeth whitening treatment. Otherwise, you may be disappointed at the end of the treatment. So, which dental clinic provides the finest teeth whitening in Menai?&nbsp;</p>
<p>Dr. Olga Palma is an experienced dental practitioner and she specialises in cosmetic dentistry. With the help of modern technology, she and her team provide the most effective treatments. This is why she is the perfect dentist for your teeth whitening treatment near Menai. So, why not visit our clinic with your family?</p>
<h2><strong>Our First Class Approach</strong>:</h2>
<p>It&rsquo;s true that the teeth whitening procedure is much simpler than other dental treatments. However, you should only visit a trained and experienced dentist for this treatment. At Dr. Olga Palma&rsquo;s clinic, we make sure that your teeth whitening is completed without any hassles.&nbsp;</p>
<p>Before we move onto the actual treatment, we do a complete dental assessment of our patients. Due to which, we are able to see whether the patient suffers from any pre-existing gum or teeth problems. Once we have completed our assessment, we will then start your treatment.&nbsp;</p>
<h2><strong>Teeth Whitening Solutions</strong></h2>
<p>Dr. Olga Palma has two teeth whitening approaches for her patients in Menai. These approaches are decided only after a detailed assessment of your teeth. Here are the teeth whitening solutions at our clinic.&nbsp;</p>
<ul>
<li><strong>Take Home Teeth-whitening Kits</strong>: With these kits, you can gradually whiten your teeth over a period of time. In this kit, there is a set of teeth whitening gel and a dental tray. You have to use this kit at night, within a few days you will start seeing the magic of our take home teeth whitening kits.</li>
</ul>
<ul>
<li><strong>In-chair Treatment</strong>: Some patients cannot wait for a few weeks before they see the results. For such cases, we recommend in-chair teeth whitening at our clinic. During this treatment, we will use some of the most advanced equipment to whiten your teeth quickly. Thus, you can smile radiantly at your upcoming business meeting or the weekend&rsquo;s social party.&nbsp;</li>
</ul>
<p>Furthermore, we make sure that our patients do not get teeth whitening side effects post-treatment. Thousands of people in and around Sydney have whitened their teeth using our solutions. So, why not book an appointment with Dr. Olga Palma today?&nbsp;</p>
<h2><strong>Affordable Treatment</strong></h2>
<p>Another reason why you should come to us is our cost-effective teeth whitening treatment. This is unusual as dental treatment in Australia is costly. But you will find the most competitive rates for each one of our dental treatments. Therefore, you do not have to break your savings in order to get this treatment.</p>
<p>Along with budget-friendly teeth whitening, we also accept payments made with AfterPay. Many patients have enjoyed the cashflow relief with this option, at their time of need. With this, you can pay for your teeth whitening at our clinic in four equal fortnightly installments. Go to the AfterPay app.&nbsp;</p>
	
	
	  </article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="teeth whitening menai" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568563978/website/services/Depositphotos_14351475_ds-min_nfhz0i.jpg" />
      </div>
    </div>
  </div>
}



export default Teethwhiteningmenai;
