import React from 'react';
import { Helmet } from 'react-helmet';

const Childrensdental = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Children Dentist | Pediatric Dentistry| Dr Olga Palma</title>
      <meta name="description" content="Dentists at Dr Olga Palma dental clinic are specialised in treating kids. Improve your Children's oral health with us. Book an appointment on website. " />
      <link rel="alternate" href="https://drolgap.com.au/childrens-dental" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au/childrens-dental" />

    </Helmet>

     <h1>Children's Dental</h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
         <p>For children especially, preventative dental care is much preferred over subjecting them to unpleasant dental treatments. You should plan regular dental consultations for your child to identify and treat diseases in the early stages. The grooves and bumps on your child's teeth can attract food particles – if not properly cleaned, they can cause tooth decay.</p><p>We also monitor your child's facial growth and dental development closely. This will help avoid the crowding of teeth, and jaw issues. In addition, we assess and address factors such as large tonsils, sleep apnoea or snoring, mouth breathing, and thumb sucking.</p><p>Generally, our mouths and faces are generally programmed to develop in a balanced way in both form and function. This natural process usually accommodates the development of all of the teeth in a straight and uncrowded manner. When this natural process is disrupted, growth appliances offer a simple and uncomplicated method of reminding nature how our face and mouth are supposed to be developing. With early intervention and the correct treatment plan, children may only need braces for the very short amount of time, and in some cases, may not need them at all.</p><p>We will also explain to them how junk food and sugary food can affect their teeth adversely, and how important it is to brush every day, as well as counsel them on their dental hygiene habits.</p><h3>Mouthguards</h3><p>If your child plays a contact sport, we offer custom-made mouthguards to allow for the best protection. You will be able to choose the colour and style.</p>
          
        </article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="Dr Olga Palma" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568564026/website/services/Depositphotos_5650270_ds-min_gqpgac.jpg" />
      </div>
    </div>
  </div>
}



export default Childrensdental;
