import React from 'react';

const Textarea = ({ errors, label, onChange, touched, value, rows, ...props }) => {
  const validationClass = touched ?
    errors ? 'border-red-500'
      : 'border-green-500 bg-white' : {};
  return <div>
    {label ? <label>{label}</label> : null}
    <textarea className={validationClass} rows={rows} onChange={onChange} value={value} {...props}></textarea>
    {touched && errors ? <p className="error">{errors}</p> : null}
  </div>
}

export default Textarea;