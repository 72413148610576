import React, { Component } from 'react';
import Modal from 'react-modal';
import parse from 'html-react-parser';

import Aux from 'hoc/_Aux/_Aux';

Modal.setAppElement('#root');

class Service extends Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false
    };
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const { borderBottom, borderRight, item: { description, imageAltUrl, imageUrl, name, url } } = this.props;

    return <Aux>
      
      <a href={url} className={`w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center justify-center h-auto lg:h-64 border-gray-300 ${borderRight ? 'border-r' : ''} ${borderBottom ? 'border-b' : ''} hover:shadow-md hover:border-0 bg-white hover:transform-scale-subtle transition-normal hover:show-child hover:bg-gray-100 hover:text-teal-700`} >
        <div className="w-32 h-32 rounded-full bg-gray-300 bg-cover bg-center m-4" style={imageUrl ? { backgroundImage: `url(https://res.cloudinary.com/drolgap/image/upload/q_auto/${imageUrl})` } : {}}></div>
        <h3 className="text-center">{name}</h3>
      </a>

      <Modal
        isOpen={this.state.modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
        style={{
          overlay: {
          }, content: {
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'hsla(100, 100%, 100%, 0.95)',
            border: 'none',
          }
        }}
        contentLabel={name}
      >
        <article className="w-full h-full flex flex-col lg:flex-row items-center p-8">        
          <div className="w-full lg:w-1/2">

            <div className="flex">
              <div className="flex-grow">
                <h2>{name}</h2>
                <div className="horizontal-line"></div>
              </div>
              <div className="w-auto">
                <button className="p-3 border-b border-teal-500 hover:text-teal-500" onClick={this.closeModal}>Back</button>
              </div>
            </div>
            <div className="overflow-y-auto pr-6 text-lg" style={{ maxHeight: '50vh' }}>
              {parse(description)}
            </div>           

          </div>
          <div className="w-full lg:w-1/2 lg:ml-8">

            <img alt={name} className="w-full h-auto my-8 lg:mt-0" src={`https://res.cloudinary.com/drolgap/image/upload/q_auto/${imageUrl}`} />
            {imageAltUrl ? <img alt={name} className="w-full h-auto" src={`https://res.cloudinary.com/drolgap/image/upload/q_auto/${imageAltUrl}`} /> : null}

          </div>
        </article>
      </Modal>
    </Aux>
  }
}

export default Service;
