import React from 'react';
import { Helmet } from 'react-helmet';

const Tmjandchronicpain = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>TMJ & Chronic Pain Treatment | Dr Olga Palma</title>
      <meta name="description" content="Dr Olga Palma Dental Clinic offers the best TMJ and chronic pain treatments. We offer affordable services from our specialist's dentists. Visit our website to know more. " />
      <link rel="alternate" href="https://drolgap.com.au/tmj-and-chronic-pain" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au/tmj-and-chronic-pain" />

    </Helmet>

     <h1>TMJ and Chronic Pain</h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
          <p>Bruxism, or teeth grinding, is a common condition that affects both adult and children. It occurs when a person grinds or clenches their teeth, usually while they're sleeping. In many cases, the person does not even know that they are doing it until their parent or partner mentions it.</p><p>Although you are not likely to hear the actual grinding of your teeth, there are several other symptoms that you may notice including morning headaches, earaches, and sore jaw muscles. Teeth grinding can also wear down, loosen, or crack your teeth, and in rare cases, cause tooth loss.</p><p>If you are experiencing any of this, you may need an occlusal splint. This plastic device comes in many different designs is worn over the teeth while sleeping to reduce wear and tear. It is custom-made to fit the specific contours of your mouth so that it is comfortable as possible to wear.</p><p>If we identify significant damage to your teeth caused by grinding, you may require some restorations to take your teeth to their original condition.</p><p>There are different designs available for night splints and the splint that is more suitable for you depends on the pattern of your grinding, your body type, and particular TMJ symptoms.</p><h3>Ortho-postural Dentistry</h3><p>In cases of chronic pain, a multidisciplinary effort between a team of healthcare professionals, such as chiropractors, physiotherapists, ENT specialists, osteopaths, etc., may be required. This is because what may seem to be a localised problem either at the mouth, neck, head, or back, may actually be compensation for something happening elsewhere.</p><p>For example, neck pain or headaches may be the reflection of lacking intraoral volume, or an airway problem. We will need to find the root cause of the problem to fix it, otherwise the symptoms will have a tendency to recur.</p>
          
        </article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="Dr Olga Palma" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568564051/website/services/Depositphotos_28486211_ds-min_llzsgb.jpg" />
      </div>
    </div>
  </div>
}



export default Tmjandchronicpain;
