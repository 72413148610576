import React from 'react';
import { Helmet } from 'react-helmet';

const Veneers = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Dental Veeners Miranda | Porcelain Veneers Miranda | Dr Olga Palma</title>
      <meta name="description" content="Beautify your smile with dental veneers from Dr Olga Palma in Miranda. Get custom designed porcelain veeners as per your requirements. Book an appointment now!" />
      <link rel="alternate" href="https://drolgap.com.au/veneers" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au/veneers" />

    </Helmet>

     <h1>Dental Veeners in Miranda</h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
    <h2><strong>Dental Veneers</strong></h2>
<p>Do you shy away from smiling in photos or while sitting across the table with someone? Don’t worry, you are not alone. Many people in Australia go through the same problem as you do. Due to one or multiple dental problems, they do not have the confidence to put a good smile on their own faces. Sounds familiar to you? But with the help of veneers, you can restore back your confidence once and for all. Dr. Olga Palma is the best place to get veneers in Miranda.</p>
<p>Dental veneers are wafer-thin shells made from either porcelain or a resin composite. Veneers do not remove stains from teeth, but they completely cover them up.</p>
<h2><strong>What Do You Mean By Veneers?&nbsp;</strong></h2>
<p>Veneers are nothing but a thin covering which is placed in front of your teeth. This is a great and easy dental solution for discoloration, chipped teeth and  gaps between the teeth. Each patient’s teeth orientation is different and thus, custom made veneers are needed. Once the procedure is completed, you can once again smile with great confidence.</p>

<p>Veneers are bonded to the front surfaces of the teeth to improve the appearance of the shape, colour, size, and alignment of the teeth. Porcelain veneers can be custom designed to suit specific aesthetic requirements.</p>

<h2><strong>We use dental veneers to improve the appearance of:</strong></h2>
<ul>
<li>Teeth which have been discoloured due to root canal treatment, stains from tetracycline or other drugs, excessive fluoride, or the presence of large resin fillings</li>
<li>Worn-down teeth</li>
<li>Chipped or broken teeth</li>
<li>Misaligned, uneven, or irregularly shaped teeth</li>
<li>Teeth with gaps between them</li>
<li>Veneers require minimal tooth shaping regarding the enamel at the front of the tooth as they are just a few millimetres thick.</li>
</ul>
<p>They are not a good choice for individuals who: clench and grind their teeth, have unhealthy or weakened teeth or those who have an inadequate amount of existing enamel on the tooth surface.</p>
<h2><strong>Why Come To Us?</strong></h2>
<p>Dr. Olga Palma has years of experience when it comes to providing the finest dental veneers in Miranda. Over the years, she has performed lots of veneer fitting procedures without any problems. And thus, you should also book an appointment at her clinic if you are looking for the best veneers in Kingsway, Miranda. Here are the benefits associated with getting your veneers at Dr. Olga Palma dental clinic.&nbsp;</p>

<h2><strong>Custom-made Veneers</strong></h2>
<p>As mentioned earlier, every patient’s dental problems are different. Some people have discoloration whereas some others have chipped or broken teeth. And therefore, their veneer requirements are different as well. at Dr. Olga Palma clinic, we scan your dental orientation with the help of our highly advanced laboratory. Once the results are back, we create veneers accordingly. </p>

<h2><strong>Minimally Invasive and Quick Procedure</strong></h2>
<p>Dental veneer fitting is one of the most minimally invasive procedures. We use cutting edge technology to remove a bit of the enamel of your teeth. And then place the custom made veneers over them. Yes, it is that easy. Similarly, you do not have to make multiple visits to our clinic. We try to finish every dental veneers procedure  as early as possible.</p>

<h2><strong>Easy To Maintain</strong></h2>
<p>Unlike other dental procedures, dental veneers are much easier when it comes to maintenance. You don’t have to follow any special routine for its maintenance. Brushing  and flossing as you will do it with your natural teeth  is more than enough. Furthermore, you can always come to visit Dr Olga Palma dental clinic and we can check the condition of your veneers. In case there’s something to be done, we will immediately start working on it.</p>
<p>Apart from the reasons given above, we have the most competitive prices for dental veneers. You can use AfterPay as well.</p>
        </article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="veneers Miranda" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568564023/website/services/Depositphotos_24727361_ds-min_b2ux42.jpg" />
      </div>
    </div>
  </div>
}



export default Veneers;
