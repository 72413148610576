import React from "react";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div className="px-2">
      <Helmet>
        <html lang="en" />
        <title>About - Dr Olga Palma</title>
        <meta
          name="description"
          content="As a specialist in health auditing, her philosophy focuses on continuous improvement and striving towards the highest standards in the treatments she provides. Because of this, she undertakes a variety of courses and seminars on the different areas of dentistry periodically."
        />
        <link
          rel="alternate"
          href="https://drolgap.com.au/about"
          hrefLang="en-AU"
        />
        <link rel="canonical" href="https://drolgap.com.au/about" />
      </Helmet>

      <h2>About</h2>
      <div className="horizontal-line"></div>
      <div className="flex -mx-2">
        <div className="w-1/2 px-2">
          <article>
            <p>
              Dr Olga Palma graduated as a Dentist in Colombia in 2002. Since
              then she has developed a great interest in several areas of
              dentistry. She took further studies in cosmetic dentistry and oral
              rehabilitation.
            </p>
            <p>
              When she arrived in Australia, she took an interest in Ortho
              postural Dentistry and orthodontics. She got to understand and
              appreciate the importance and relationship between oral health and
              dental structures to the well-being of the whole body, as well as
              the early assessment and approach to jaw growth and malocclusion.
            </p>
            <p>
              As a specialist in health auditing, her philosophy focuses on
              continuous improvement and striving towards the highest standards
              in the treatments she provides. Because of this, she undertakes a
              variety of courses and seminars on the different areas of
              dentistry periodically.
            </p>
            <p>
              Dr Palma is also known for her gentle approach and genuine caring
              for her patient's needs.
            </p>
          </article>
        </div>
        <div className="w-1/2 px-2">
          <img
            alt="Dr Olga Palma"
            src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1567657696/website/about/IMG_7262-edit-small_j8xaxm.jpg"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
