import React from 'react';
import { Helmet } from 'react-helmet';

const Snoringandsleepapnoea = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Snoring Sleep Apnea | Dr Olga Palma</title>
      <meta name="description" content="Looking for snoring & sleep apnoea treatment? We offer the best treatment for snoring & sleep apnoea. To know more visit our website or call us on 0416 966 839 today!" />
      <link rel="alternate" href="https://drolgap.com.au/snoring-and-sleep-apnoea" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au/snoring-and-sleep-apnoea" />

    </Helmet>

     <h1>Snoring and Sleep Apnoea</h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
         <p>Snoring causes disrupted sleep for both the snorer and their bed partner. Sometimes it may appear that the snorer has no trouble sleeping, but snoring may be a clue to a much more serious problem – sleep apnoea.</p><p>If you have sleep apnoea, even mild sleep apnoea, your airway is closing off repetitively while you sleep. When your airway closes, you cannot breathe, your blood oxygen drops, and your blood pressure rises. As you rouse, your heart races, you over-breathe, your blood pressure increases even further, and you drift off to sleep again only to have this sequence repeated over and over again – sometimes hundreds of times per night. People with sleep apnoea are at a high risk of a heart attack or stroke.</p><p>Diagnosis and treatment are easy compared to the consequences, and prevention is much better than cure. The method for diagnosis is with a sleep study. Many options exist to assist the sufferer.</p><h3>The Dorsal Appliance</h3><p>The Dorsal Appliance keeps your airway open and protected, eliminating snoring and sleep apnoea incidents.</p><p>It is worn only during sleep, comfortable and unobtrusive to wear. It fully captures the mandible into the registration position as the patient drops into deep sleep. The binding effect ensures that the mandible does not rotate open.</p>
          
        </article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="Dr Olga Palma" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568564046/website/services/IMG_6979-min_upgk8y.jpg" />
      </div>
    </div>
  </div>
}



export default Snoringandsleepapnoea;
