import React from 'react';
import { Helmet } from 'react-helmet';

const CosmeticInjections = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Cosmetic Injections Miranda | Dr Olga Palma</title>
      <meta name="description" content="Cosmetic Injections help on relieving pain and symptoms from severe grinding or bruxism. Book an appointment in Miranda." />
      <link rel="alternate" href="https://drolgap.com.au/cosmetic-injections" hrefLang="en-AU" />
      <link rel="canonical" href="https://drolgap.com.au/cosmetic-injections" />

    </Helmet>

    <h1>Cosmetic Injections In Miranda</h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
          <p>
            Although we are not a cosmetic clinic and our focus is not on anti-wrinkle treatments or dermal fillers, there are some therapeutic application of cosmetic injections in Dentistry.  Dr Palma is certified and  applied these knowledge towards 2 main areas.
          </p>


          <h3>Relieving pain and symptoms from severe grinding or bruxism</h3>          
          <p>Most people are familiar with botulinum toxin to get rid of wrinkles. But recent studies  have shown that botulinum toxin's muscle-relaxing properties show promise in stopping jaw clenching and teeth grinding. At least for some time.  The injections  are on each side  directed straight into your masseter muscle. You can feel this muscle along the side of your jaw when your teeth are clenched; it controls chewing and the raising of your jawbone. But when it's in a constant state of tension, you may spend many mornings waking up to headaches, jaw pains.  Botulinum toxin will reduce clenching, and it will also help with any accompanying tension and aches in your head that you may be experiencing.  It usually kicks in about 1 to 3 days after injection (but can take up to two weeks), and the effects could last 3 to 6 months. </p>
          <h3>Improve the appearance of Gummy smile</h3>
          <p>There is a relatively simple aesthetic solutions to minimise the appearance of your gums when you smile, without having to worry about surgery.  The technical term for a gummy smile is excessive gingival display and it’s defined when there is 2-4 mm of gum visible when smiling. A gummy smile is the result of various factors, whether it be an excessive display of gum tissue that makes teeth appear shorter than they are, a thin upper lip,  overactive muscles around the upper lip, or the way in which the upper jawbone developed could also be the cause.</p>
          <p>Anti-wrinkle injections help those whose gummy smile is the result of overactive muscles around the upper lip. A small amount is injected to reduce the activity of this muscle and reduce the height of the upper lip, as the newly relaxed muscles are not pulling at the upper lip as much the gummy smile decreased. Anti-wrinkle injections can last for up to 6 months, are non-invasive and only take around 30 minutes to administer with no recovery time needed.  One side effect of this treatment is the loss of some function of the upper lip, and difficulties with sucking on a straw, sipping tea or whistling. This problem is usually short-lived and well tolerated by most who have the treatment.</p>
        </article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="dentist sutherland shire" src="https://res.cloudinary.com/drolgap/image/upload/v1614759567/website/services/cosmetic-injections_rwmlre.jpg" />
      </div>
    </div>
  </div>
}



export default CosmeticInjections;
