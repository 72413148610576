import React from 'react';
import { Helmet } from 'react-helmet';

const Dentalimplants = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Dental Implants | Dr Olga Palma</title>
      <meta name="description" content="Dr Olga Palma offers best dental implants from the expert dentist. We offer dental implants at affordable prices. Visit our website to know more." />
      <link rel="alternate" href="https://drolgap.com.au/dental-implants" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au/dental-implants" />

    </Helmet>

     <h1>Dental Implants</h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
          <p>Dental implants often prove to be the best solution in cases where patients are missing one or more teeth. They create the most authentic and natural appearing smiles, and the new teeth feel exactly like natural teeth.</p><p>A dental implant is a highly durable cylindrical block of titanium that is surgically placed into the jawbone – this surgery is the first stage of the procedure and a specialist will perform the surgery for you. After the implant is placed, it will completely integrate with the jawbone and becomes a part of the body itself. The second stage can be done by us and will involve restoring the tooth via a dental crown, dental bridge, or partial dentures.</p><p>There are numerous benefits to dental implants.</p><ul class="mylist"><li>They are a permanent solution, providing teeth that look, feel, and function like natural teeth.</li><li>They do not slip, so there is no discomfort or embarrassment.</li><li>Can be cleaned along with natural teeth.</li><li>Implants are strong enough to provide a reliable foundation for chewing.</li><li>They prevent bone loss and maintain the jawbone's shape and density. This ensures the integrity of the facial structure and facial appearance.</li><li>Implants do not compromise the health of surrounding teeth, unlike traditional options such as bridges.</li></ul>
          
        </article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="Dr Olga Palma" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568564027/website/services/Depositphotos_32655591_ds-min_wp09yv.jpg" />
      </div>
    </div>
  </div>
}



export default Dentalimplants;
