import React from "react";
import { Helmet } from "react-helmet";

const Rootcanaltreatment = () => {
  return (
    <div className="px-2">
      <Helmet>
        <html lang="en" />
        <title>Root Canal Treatment in Miranda, Sydney | Dr Olga Palma</title>
        <meta
          name="description"
          content="Seeing signs of decay or infection in your tooth? Waste no time, look into getting root canal treatment from renowned dentist Dr. Olga Palma in Miranda."
        />
        <link
          rel="alternate"
          href="https://drolgap.com.au/root-canal-treatment"
          hrefLang="en-AU"
        />
        <link
          rel="canonical"
          href="https://drolgap.com.au/root-canal-treatment"
        />
      </Helmet>

      <h1>Root Canal Treatment in Miranda, Sydney </h1>
      <div className="horizontal-line"></div>
      <div className="flex -mx-2">
        <div className="w-1/2 px-2">
          <article>
            <p>
              When the decay in your tooth has advanced to such an extent that
              it has affected the inner dental pulp and the roots of the tooth,
              the only option left to save your tooth is a root canal treatment.
            </p>
            <p>
              You might possibly require a root canal treatment if you have
              sharp pain or a dull ache in or around your tooth, or severe or
              prolonged sensitivity to hot and cold temperature. An abscess
              found around the tooth root or the gum tissues surrounding the
              tooth is an indicator of a severe infection. If neglected, this
              abscess will lead to the loss of your tooth, and the infection may
              even spread into the blood and jaw bone.
            </p>

            <p>
              The treatment may take two appointments. The infected pulp is
              scooped out and the area is sterilised. The hollowed-out tooth is
              then packed with an inert material to prevent further infection.
              If required, a dental crown may be used to cap off the tooth
              treated by the root canal to restore normal functionality.
            </p>

            <p>
              Many people must have heard about root canal treatment from their
              neighbours, family or friends. But still, most of us don’t
              actually know what happens in this treatment or why exactly it is
              given. And most probably, people associated a great deal of pain
              to root canal treatment even though it is not true. In reality,
              root canal treatment is a pain-relieving procedure done on the
              infected pulp of your tooth. This procedure is highly effective in
              removing the decay around your root canals and ultimately helps
              save your tooth.{" "}
            </p>
            <p>
              If we consider that the root canal configuration or individual
              circumstances of the tooth are challenging, you will be referred
              to a specialist endodontist.
            </p>
            <p>
              Dr. Olga Palma is one of the finest dentists in Miranda and has
              successfully performed various dental procedures. She possesses
              great wisdom and experience when it comes to providing flawless
              root canal treatment, unlike dental clinics, you won’t have to pay
              an unreasonable amount of money for her dental care. So why not
              pay a visit to our clinic in Kingsway, Miranda and get the best
              root canal treatment today?{" "}
            </p>

            <h2>
              <strong>Why Choose Us?</strong>
            </h2>
            <p>
              Only an experienced dentist should perform a root canal treatment
              which is also known as endodontic treatment. Otherwise, you will
              face a lot of complications later. This is why you should get this
              treatment at Dr. Olga Palma’s dental clinic.{" "}
            </p>

            <h2>
              <strong>
                Cutting Edge Technology and Budget-friendly Treatment
              </strong>
            </h2>
            <p>
              A single mistake or miscalculation in this procedure can lead to
              many complications later. At Dr. Olga Palma dental clinic, we use
              the latest equipment and imaging systems to properly execute this
              treatment. Due to such reasons, you can expect an error-free and
              highly effective root canal treatment .{" "}
            </p>
            <p>
              We understand that money can be a significant constraint for many
              of our patients, which is exactly why we charge the most
              affordable price for our root canal treatment in Miranda.
              Furthermore, you can also use AfterPay to pay the fees at Dr. Olga
              Palma dental clinic in Kingsway, Miranda.{" "}
            </p>
          </article>
        </div>
        <div className="w-1/2 px-2">
          <img
            alt="root canal treatment miranda"
            src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568564040/website/services/RX01-min_npo0gh.jpg"
          />
        </div>
      </div>
    </div>
  );
};

export default Rootcanaltreatment;
