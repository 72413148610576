import "./Contact.css";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import * as Yup from "yup";

import axios from "axios";
import Button from "components/Basic/Button/Button";
import Input from "components/Basic/Input/Input";
import Textarea from "components/Basic/Textarea/Textarea";

import Map from "components/Map/Map";

const validationSchema = Yup.object().shape({
  nameaksljf: Yup.string().required("Please enter your name."),
  emailaksljf: Yup.string()
    .email("This email doesn't appear to be valid.")
    .required("Please enter your email so that we can get back to you."),
  messageaksljf: Yup.string()
    .max(
      2000,
      "Please restrict your initial message to 2000 characters. We can get into more detail soon!"
    )
    .required("Let us know what you'd like to contact us about."),
});

class Contact extends Component {
  state = {
    displaySuccessNotification: false,
    displayErrorNotification: false,
  };

  postContactHandler(values) {
    const { name, email, nameaksljf, emailaksljf, messageaksljf } = values;
    if (!name && !email) {
      // Send email with contact us details

      axios
        .post(
          "https://bvzmut03da.execute-api.us-east-1.amazonaws.com/prod/submit",
          {
            name: nameaksljf,
            email: emailaksljf,
            message: messageaksljf,
          }
        )
        .then((response) => {
          this.setState({ displaySuccessNotification: true });
        })
        .catch((error) => {
          this.setState({ displayErrorNotification: true });
        });
    }
  }

  render() {
    return (
      <div className="px-2">
        <Helmet>
          <html lang="en" />
          <title>Contact - Dr Olga Palma</title>
          <meta
            name="description"
            content="If you have any questions, please fill out the form and we will get back to you as soon as possible! Or give us a call."
          />
          <link
            rel="alternate"
            href="https://drolgap.com.au/contact"
            hrefLang="en-AU"
          />
          <link rel="canonical" href="https://drolgap.com.au/contact" />
        </Helmet>

        <article>
          <h2>Contact Us</h2>
          <div className="horizontal-line mb-4"></div>
          <p className="pb-0">
            If you have any questions, please fill out the form and we will get back
            to you as soon as possible! Or give us a call.
          </p>
          <p>
            {" "}
            <i className="far fa-phone-alt"></i>{" "}
            <a href="tel:0416966839">0416 966 839</a>
          </p>
        </article>
        <Formik
          initialValues={{
            nameaksljf: "",
            emailaksljf: "",
            name: "", // HONEYPOT
            email: "", // HONEYPOT
            messageaksljf: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            this.postContactHandler(values);
            setSubmitting(false);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className="mb-6 py-6" onSubmit={handleSubmit}>
              <div className="flex flex-wrap -mx-2">
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <Input
                    errors={errors.nameaksljf}
                    label="Name"
                    name="nameaksljf"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    touched={touched.nameaksljf}
                    type="text"
                    value={values.nameaksljf}
                  />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <Input
                    errors={errors.emailaksljf}
                    label="Email"
                    name="emailaksljf"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    touched={touched.emailaksljf}
                    type="email"
                    value={values.emailaksljf}
                  />
                </div>
                {/* ---- HONEYPOT START ---- */}
                <div className="ohnohoney">
                  <Input
                    label="Name"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    touched={touched.name}
                    type="text"
                    value={values.name}
                  />
                </div>
                <div className="ohnohoney">
                  <Input
                    errors={errors.email}
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    touched={touched.email}
                    type="email"
                    value={values.email}
                  />
                </div>
                {/* ---- HONEYPOT END ---- */}
              </div>
              <div className="flex flex-wrap -mx-2">
                <div className="w-full px-2 mb-4">
                  <Textarea
                    errors={errors.messageaksljf}
                    label="Message"
                    name="messageaksljf"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    touched={touched.messageaksljf}
                    value={values.messageaksljf}
                    rows={8}
                  />
                </div>
              </div>
              <div className="text-center mt-2">
                <Button
                  buttonStyle="bg-blue-500 text-white py-2 px-4 uppercase tracking-wide"
                  disabled={isSubmitting || this.state.displaySuccessNotification}
                  errors={errors}
                  type="submit"
                >
                  Send
                </Button>
              </div>
            </form>
          )}
        />
        {this.state.displaySuccessNotification ? (
          <div
            className="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3 mb-8"
            role="alert"
          >
            <svg
              className="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
            </svg>
            <p className="text-lg">
              Enquiry sent successfully! We will get back to you as soon as
              possible. Thanks.
            </p>
          </div>
        ) : null}
        {this.state.displayErrorNotification ? (
          <div
            className="flex items-center bg-red-400 text-white text-sm font-bold px-4 py-3 mb-8"
            role="alert"
          >
            <svg
              className="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
            </svg>
            <p className="text-lg">
              Enquiry was not sent! Please try again in a few minutes. Sorry for
              the inconvenience.
            </p>
          </div>
        ) : null}
        <Map />
      </div>
    );
  }
}

export default Contact;
