import './Medicare.css';
import React from 'react';
import { Helmet } from 'react-helmet';

const Medicare = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Dental Vouchers by Medicare In Miranda | Dr Olga Palma </title>
      <meta name="description" content=" Medicare dental vouchers lets your child get FREE dental check ups & other services. For Paediatric Dentistry, visit Dr Olga Palma in Miranda. Book Now!" />
      <link rel="alternate" href="https://drolgap.com.au/medicare" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au/medicare" />
    </Helmet>
    <h1>Dental Medicare Vouchers for Your Child's Dental Care In Miranda </h1>

   
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
         <h2>Medicare (CDBS)</h2>
         <p>It is important to impart good oral hygiene on our children from a very young age. Otherwise, they may develop several dental problems later. One of the best ways to do this is by bringing them to a dentist every once in a while. Similarly, if your children are having some dental problems then you should visit a dentist straight away. However, many parents avoid visiting a dentist due to expensive bills they receive at the end. To prevent this from happening, the Australian Government has come up with a Medicare Child Dental Benefits Schedule scheme. </p>
		 
<p>So, are there any good dentists which accept the Medicare dental vouchers in Miranda? Yes, there are many dentists in this suburb which accept the Medicare CDBS scheme. And fortunately, you have stumbled upon one such dentist’s website. And today, we will explain the benefits, eligibility criteria and other aspects of these medicare dental vouchers in Miranda. </p>
<h2><strong>Do We Accept Medicare CDBS Scheme?</strong></h2>
<p>Dr Olga Palma supports the Medicare Child Dental Benefits Schedule (CDBS). Over the years, many  parents have brought their children to our clinic. Fortunately due to these Medicare funded dental vouchers, these children  have received free treatment at our clinic.</p>

<h2><strong>What Is Medicare CDBS Scheme?</strong></h2>
<p>The new Medicare child dental schedule commenced on 1 January 2014 as a replacement for the existing Teen Scheme dental voucher system and entitles your child to a $1,000 dental benefit to be used over a two year period. </p>

<h2><strong>Treatments Covered Under This Scheme</strong>&nbsp;</h2>
<p>Now, one of the most asked questions regarding this scheme is “which treatments are covered in this scheme?” So, the simple answer would be - only the most basic dental care is covered under the Medicare CDBS scheme. The Scheme is designed to enable children to receive regular dental check-ups and general dental procedures. Under this benefit, your child is eligible for the following services:</p>
<ul>
<li>A dental check-up every six months, which includes assessments of the orthopaedic growth of your child's jaw and an analysis of your child's dental occlusion;</li>
<li>Any x-rays that may be required;</li>
<li>Full oral hygiene (cleaning) treatment;</li>
<li>Fissure sealants;</li>
<li>Deciduous tooth extractions;</li>
<li>Fillings; and</li>
<li>Partial dentures.</li>
</ul>
<h2><strong>Treatments That Are Not Covered</strong></h2>
<p>We have already covered the treatments which you can get under this scheme. However, it’s also important to know which schemes are not covered by medicare dental vouchers. This will help you to avoid any misunderstanding and complications in case there’s an emergency. Here are the treatments not covered under Medicare CDBS scheme. 
</p>
<ul>
<li>Orthodontic</li>
<li>Cosmetic dental work</li>
<li>Surgeries of any kind</li>
</ul>
<p>For the above-mentioned dental problems, you have to pay out of your pockets. As these treatments are not covered under the Medicare CDBS scheme. Now, let’s see who are eligible for the medicare dental vouchers.</p>
<h2><strong>Who Is Eligible?</strong></h2>
<p>The government has given certain eligibility criteria for this scheme. And here are the criteria which you must fulfill in order to get basic dental treatments for free.  </p>
<ul>
<li>The children must be in the age group of 2 to 17 years.&nbsp;</li>
<li>Plus, the family must receive Family Tax Benefit Part A. Or the family should receive other relevant Australian Government Payment.</li>
</ul>
<p>However, there are other conditions which are also relevant to the eligibility for Medicare CDBS scheme. You should check it out from the official websites.</p>

<h2><strong>How To Use Medicare Dental Vouchers?</strong></h2>
<p>For those of our patients that are eligible for it, we will bulk-bill for all treatment covered by the Medicare schedule. A 'gap' payment will only be payable should your child's treatment exceed the specified $1,000 allowance, or if treatment required is not covered by the schedule. We will let you know if your treatment may incur a gap payment. For more information, please see the <a href="https://www.humanservices.gov.au/individuals/services/medicare/child-dental-benefits-schedule">Child Dental Benefits Schedule</a>.</p>
<p>So, make sure to come down to Dr. Olga Palma’s dental clinic in Miranda. She is amongst the best dentists in Miranda for child as well as adult dental care. With the help of cutting edge technology, we are able to provide the finest dental care for you and your family.
</p>
<p><br /><br /></p>
         
            </article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="medicare dental vouchers miranda" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568137290/website/medicare/Depositphotos_11633185_ds-min_vxnr7q.jpg" />
      </div>
    </div>
  </div>
}

export default Medicare;
