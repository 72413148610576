import React from 'react';
import { Helmet } from 'react-helmet';

const Cosmeticdentistry = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Cosmetic Dentist Miranda | Dr Olga Palma</title>
      <meta name="description" content="Cosmetic dentistry is generally used to refer to any dental work that improves the appearance of teeth, gums and/or bite. Book an appointment in Miranda!" />
      <link rel="alternate" href="https://drolgap.com.au/cosmetic-dentistry" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au/cosmetic-dentistry" />

    </Helmet>
<h1>Cosmetic Dentist Treatment in Miranda</h1>
     
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
         <p>Many of us shy away from putting a good smile on our face. And there are many reasons behind this, such as chipped, broken, stained or discolored teeth, etc. Not only does it affect our day-to-day social lives but also puts a toll on our overall health. You can easily get back your charming  smile with the help of cosmetic dentistry</p>
<p>So, what exactly do we mean by cosmetic dentistry and where to find the best cosmetic dentist in Miranda? We have covered everything that you need to know about cosmetic dentistry below. </p>
<p>Cosmetic dentistry focuses on improving the appearance of your mouth, teeth and smile. It includes procedures from simple, safe tooth whitening to more complex work, like implants, crowns, veneers, composite bonding, or straightening. During your initial consultation, we will explain what options are available for you and advise a treatment plan that will help you gain your dream smile.</p>
<h2><strong>All-Inclusive and Reliable Cosmetic Dentistry&nbsp;</strong></h2>
<p>Dr. Olga Palma is a brilliant dentist, thousands of patients have visited our clinic and have returned with a broad smile due to our excellent cosmetic dentistry. We only use the latest equipment and the best practices while we work on fixing your smile. 
At our clinic, you will get every type of cosmetic dental treatment at the most competitive price in Sydney.  Here are some of the cosmetic dental treatments available at our clinic. </p>
<p><strong>Teeth Whitening</strong>: No one likes to see yellow teeth when they smile. And that is why, we have come up with the complete teeth whitening solution for you. You can either opt for take-home whitening kits or in-chair teeth whitening service at our clinic. With our teeth whitening solutions, you do not have to hide your smile.</p>
<p><strong>Dental Veneers</strong>: Have a broken or chipped tooth? We can definitely fix it with our dental veneers treatment. This is a pretty simple yet very effective dental treatment. And at Dr. Olga Palma clinic, we place porcelain or resin composite veneers over existing natural teeth. With our excellent treatment, you never have to hesitate before putting a smile on your face.</p>
<p><strong>Crowns and Bridges</strong>: In some cases, you can save your natural tooth with the help of cosmetic dentistry. And for this, we use crowns and bridges to fill the gaps in-between your teeth. We use porcelain crowns and bridges in this treatment which will surely last for at least 10 to 15 years. Not only is this highly effective but is also affordable at our clinic.</p>

<p>Similarly, there are various other cosmetic dental treatments available at our clinic in Kingsway, Miranda. And you can check them out below.&nbsp;</p>
<ul>
<li>Amalgam Removal</li>
<li>Dental Implants</li>
<li>Dentures</li>
<li>Orthodontics&nbsp;</li>
</ul>
<p>Due to such a wide range of treatments, we are regarded as the best cosmetic dentist in Miranda.</p>
<h2><strong>We Accept AfterPay</strong></h2>
<p>Apart from our affordable cosmetic dental services, we also accept your payments through AfterPay.</p>

        </article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="cosmetic dentist miranda" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568564009/website/services/Depositphotos_32186561_ds-min_hoicpi.jpg" />
      </div>
    </div>
  </div>
}



export default Cosmeticdentistry;
