import React from 'react';
import { Helmet } from 'react-helmet';

const Dentistcronulla = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Professional Dentist In Cronulla | Dr Olga Palma</title>
      <meta name="description" content="Dr. Olga Palma has Dentists in Cronulla with a gentle, friendly approach to help you look after your oral hygiene. Visit our website to book an appointment!" />
      <link rel="alternate" href="https://drolgap.com.au/dentist-cronulla" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au/dentist-cronulla" />

    </Helmet>

     <h1>Professional Dentist In Cronulla  </h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
		<p>Maintaining good oral hygiene is very necessary for not only for children but for adults as well. It allows you to put a great smile on your face every day. But there are some unavoidable circumstances when you have to visit a dentist nearby. So, are you in search of the best dentist near Cronulla? Choosing a dentist can be a difficult task especially if you are new in the city or have never been to one before. Don&rsquo;t worry, we know the ideal dentist in Miranda for your dental care.&nbsp;</p>
<p>In 2002, Dr. Olga Palma had completed her graduation in Colombia. Since her move to Australia, she has delivered countless dental treatments at her clinic in Miranda, Sydney. You can also visit the clinic for quality dental treatments such as cosmetic dentistry, orthodontics, etc.&nbsp;</p>
<h2><strong>Why Come To Us?</strong></h2>
<p>Now you may wonder, why should you visit Dr. Olga Palma&rsquo;s dental clinic when there are so many other dentists in Cronulla. we understand this sentiment of yours. That is why, we have explained the benefits that you get at our clinic below.&nbsp;</p>
<h2><strong>Our Services and Treatments</strong></h2>
<p>At Dr. Olga Palma clinic, you will get the finest dental care near Cronulla. We have the latest technology and equipment to treat every patient we receive at our clinic. Our years of experience and expertise allows us to give the most effective dental care in the city. Apart from the treatments mentioned before, Dr. Olga and her team specialise in the following dental services.&nbsp;</p>
<ul>
<li>Amalgam Removal</li>
<li>Oral Hygiene</li>
<li>Teeth Whitening</li>
<li>Crowns and Bridges</li>
<li>Dental Veneers</li>
<li>Dental Implants</li>
<li>Dentures</li>
<li>Teeth Extraction&nbsp;</li>
<li>Root Canal Therapy</li>
<li>Snoring and Sleep Apnoea</li>
</ul>
<p>Furthermore, we specialise in children&rsquo;s dental care as well. As we believe every child needs to develop good oral hygiene at a very young age. All of these services and treatments are given at the most competitive pricing in Australia. that is why we are amongst the best dentists near Cronulla,&nbsp;</p>
<h2><strong>Medicare (CDBS)</strong></h2>
<p>As mentioned earlier, children dental care ranks very high on our priority. That is why we support the government&rsquo;s Medicare Child Dental Benefits Schedule (CDBS) scheme. With this scheme, your children shall receive lots of benefits when it comes to dental care. Over the period of 2 years, you can claim benefits up to $1000 for your child&rsquo;s dental care. these benefits include -&nbsp;</p>
<ul>
<li>Check up after every 6 months</li>
<li>X-rays</li>
<li>Oral hygiene and cleaning</li>
<li>Tooth extraction</li>
<li>Fillings and lots more!</li>
</ul>
<p>With so many child care benefits, we are indeed one of the best dentists near Cronulla.&nbsp;</p>
<h2><strong>Ease Of Payment</strong></h2>
<p>People usually avoid going to a dentist due to exorbitantly priced dental treatments. However, Dr. Olga Palma only charges reasonable fees for her dental care. Thus, you can visit her clinic for your dental problems without worrying about prices. Moreover, she accepts AfterPay payments as well. Here are the benefits you get while paying through AfterPay at Dr. Olga Palma dental clinic.&nbsp;</p>
<ul>
<li>Pay up to $1500 using AfterPay</li>
<li>Payment in 4 installments</li>
<li>Easy Eligibility Criteria</li>
<li>Simple Registration on AfterPay app</li>
<li>No interest on timely installment payments</li>
</ul>
<p>A great list of dental treatments, Medicare options and flexible payment options are the hallmark of a good dentist. Dr. Olga Palma is definitely amongst the top dentists. To book an appointment, visit our website immediately.&nbsp;</p>

</article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="dentist cronulla" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568564023/website/services/Depositphotos_24727361_ds-min_b2ux42.jpg" />
      </div>
    </div>
  </div>
}



export default Dentistcronulla;
