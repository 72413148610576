import React from 'react';
import { Helmet } from 'react-helmet';

const Teethwhiteningsutherlandshire = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Teeth Whitening Service In Sutherland Shire | Dr Olga Palma</title>
      <meta name="description" content="Everyone loves an luminous smile. Dr Olga Palma offers teeth whitening treatment in Sutherland Shire. Book today for brighter teeth!" />
      <link rel="alternate" href="https://drolgap.com.au/teeth-whitening-sutherland-shire" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au/teeth-whitening-sutherland-shire" />

    </Helmet>

     <h1>Teeth Whitening Services In Sutherland Shire </h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
		
		<p>There are more than a dozen reasons for putting on a happy smile. But many people are afraid to smile due to discolouration or stains on their teeth. So, what should you do in this case? The simple answer would be to get professional teeth whitening. Dr. Olga is amongst the best dentists for teeth whitening in Sutherland Shire. If you don&rsquo;t believe us yet, then make sure to see what we have below.&nbsp;</p>
<p>Dr. Olga Palma is one of the most trustworthy dentists in Australia when it comes to cosmetic dentistry. At her clinic, she performs various dental treatments including teeth whitening for the most competitive prices in the country. Over the years, she has worked wonders when it comes to teeth whitening in Sutherland Shire.</p>
<p>Every single one of her customers has returned with a great smile. Dr. Olga Palma will definitely restore the glowing smile on your face. So, come down to her clinic for the best teeth whitening treatment in Sutherland Shire. if you are still not sure whether to make the visit to our clinic or not. Read a few things regarding our services.&nbsp;</p>
<h2><strong>Our Comprehensive Approach</strong></h2>
<p>Teeth whitening is usually a simple process which yields great results at the end. But your dentist should always keep a few things checked before performing this dental treatment. This is why Dr. Olga Palma is the perfect dentist for your teeth whitening in Sutherland Shire. She uses the most advanced technology and equipment to carry out the treatment with care and great expertise. Here is how we provide teeth whitening to our customers.&nbsp;</p>
<p>Currently, there are two teeth whitening approaches we have at our clinic which are:</p>
<ul>
<li><strong>Take Home Teeth-whitening Kits</strong>: You can use a kit to whiten your teeth at your home. This kit contains a dental tray and teeth whitening gel.</li>
<li><strong>In-chair Teeth-whitening</strong>: In case you want to have an immediate teeth whitening solution then you can opt for this treatment. In this, we perform a non-invasive process to restore the beauty and whitening of your teeth.</li>
</ul>
<p>We provide you with the above-mentioned teeth whitening solutions at the most affordable price in Sutherland Shire. So, you do not have to look elsewhere for your yellow or stained teeth. We have everything you need when it comes to comprehensive and effective teeth whitening solutions.</p>
<h2><strong>We Accept AfterPay</strong></h2>
<p>Many people avoid going to the dentist due to exorbitant dental treatment costs. But you should never do this as it may lead to permanent dental issues in the future. This is the reason why at Dr. Olga Palma&rsquo;s clinic, we accept AfterPay payment. If you do not know the benefits of using this payment system then read the following -&nbsp;</p>
<ul>
<li>Using AfterPay, you can pay the treatment cost in four equal fortnightly installments.&nbsp;</li>
<li>However, you can only do this for payments up to $1500.</li>
<li>There is no interest fee for timely payments.&nbsp;</li>
<li>It&rsquo;s very easy to register for these services.&nbsp;</li>
</ul>
<p>To book an appointment, visit our website immediately.&nbsp;</p>
		
		
		     </article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="teeth whitening sutherland shire" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568563978/website/services/Depositphotos_14351475_ds-min_nfhz0i.jpg" />
      </div>
    </div>
  </div>
}



export default Teethwhiteningsutherlandshire;
