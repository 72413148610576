import React from 'react';
import { Helmet } from 'react-helmet';

import Map from 'components/Map/Map';

const Home = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Dental Clinic Miranda, Sydney | Spanish Speaking Dentist Sydney</title>
      <meta name="description" content="We are one of the leading Spanish speaking dental clinic in Miranda, Sydney. Visit us for appointments with our dentists for invisalign, whitening, root canal etc." />
      <link rel="alternate" href="https://drolgap.com.au" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au" />
    </Helmet>

    <div className="mb-12">
      <img alt="dentist Miranda" className="hidden lg:block" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1567660180/website/home/image_1-min_idhiig.jpg" />
      <div className="block lg:hidden h-48 lg:h-auto lg:w-48 flex-none bg-cover overflow-hidden" style={{ backgroundImage: 'url(https://res.cloudinary.com/drolgap/image/upload/q_auto/v1567660180/website/home/image_1-min_idhiig.jpg)' }}></div>
    </div>

    <div className="lg:flex lg:-mx-4 mb-12">
      <div className="w-full lg:w-1/3 self-stretch lg:px-4 mb-12 lg:mb-0">

        <div className="h-full bg-teal-700 max-w-sm w-full lg:max-w-full lg:flex">
          <div className="p-6 flex flex-col justify-between self-center">
            <div className="text-xl text-white">New patients are welcome!</div>
          </div>
          <div className="h-48 lg:h-auto lg:w-48 flex-none bg-cover overflow-hidden" style={{ backgroundImage: 'url(https://res.cloudinary.com/drolgap/image/upload/q_auto/v1567660180/website/home/image_2-min_kaqd1z.jpg)' }}>
          </div>
        </div>

      </div>
      <div className="w-full lg:w-1/3 self-stretch lg:px-4 mb-12 lg:mb-0">

        <div className="h-full bg-teal-500 max-w-sm w-full lg:max-w-full lg:flex">
          <div className="p-6 flex flex-col justify-between self-center">
            <div className="text-xl text-white">In-chair whitening $400</div>
          </div>
          <div className="h-48 lg:h-auto lg:w-48 flex-none bg-cover overflow-hidden" style={{ backgroundImage: 'url(https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568138718/website/home/image_3-min_kysatd.jpg)' }}>
          </div>
        </div>

      </div>
      <div className="w-full lg:w-1/3 self-stretch lg:px-4 mb-12 lg:mb-0">

        <div className="h-full bg-teal-300 p-6 flex flex-col justify-between self-center">
          <div className="text-xl text-white">Have you had a dental emergency? Call our surgery right away and we will do our best to find a time to see you.</div>
        </div>

      </div>
	   
    </div>
	
 <article>
 <h1>Dental Clinic In Miranda, Sydney | Spanish Speaking Dentist</h1>
	  <p>Dental problems can get complicated if not treated earlier, which is why you should always rush to a dental clinic with experienced dentists when you encounter any dental issues. But it’s not easy to find such experts nearby. Especially if you are one of those who only speak Spanish and cannot communicate in English and you are searching for a top dentist  who also speaks Spanish.</p>
	  
	 <p>Dr. Olga Palma is one of the most trusted general dentists in Miranda. Over the years of her practice, she has provided excellent dental care to thousands of people in the country. With her vast experience and emphasis on cutting edge technology, you can always expect the finest dental treatment at her clinic. </p>

  <p>Dr. Olga Palma is arguably the finest Spanish speaking dentist in Miranda. She graduated as a dentist from her native country of Colombia back in 2002.</p>

<p>Dr Palma and her brilliant team have years of experience and expertise to deal with every patient that walks through their door. Here are the dental treatments available at this brilliant dental clinic in Miranda.</p>
<ul>
<li>Cosmetic Dentistry</li>
<li>Amalgam Removal</li>
<li>Oral Hygiene</li>
<li>Teeth Whitening</li>
<li>Crowns and Bridges</li>
<li>Dental Veneers</li>
<li>Dental Implants&nbsp;</li>
<li>Orthodontics and much more!</li>
</ul>
<p>Apart from the services listed above, we specialise in children’s dental treatments as well. Due to such a wide range of treatments available at Dr. Olga Palma, you don’t have to go elsewhere. We have everything you need when it comes to first class dental services in Australia.</p>
 

<h2><strong>We Understand Everyone&rsquo;s Budget</strong></h2>
<p>At Dr. Olga Palma dental clinic, we understand that not everyone can afford exorbitant dental care fees. Let’s face it, we all have seen such dental clinics, which issue over-priced bills when your treatment is done. But at our clinic, it’s different. Let’s see how.</p>

<p><strong>AfterPay</strong>: Yes, you heard it right. We accept AfterPay payment which is one of the most popular payment gateway in Australia. And therefore, you can pay up to $1500 for your dental treatment in four installments at Dr. Olga Palma. You can do this without paying any interest, thanks to AfterPay. You just need a credit card or a debit card to get AfterPay benefits at our clinic. Also, make sure to check the terms and conditions for AfterPay payment at our official website. </p>

<p><strong>Medicare (CDBS): </strong> Children are at great risk when it comes to dental problems due to poor oral hygiene. And thus, we support the Child Dental Benefits Schedule (Medicare) at Dr. Olga Palma. With this, receive up to $1000 treatment for your child over a period of 2 years. Due to CDBS, your children will get dental check ups every 6 months, full oral hygiene cleaning and much. more. Isn’t this great for your little ones? It definitely is. </p>

<p>So, what are you waiting for? You won’t find a better dentist. Also, make sure to visit our <a href="https://drolgap.com.au/">website</a> to know more!&nbsp;</p>
 </article>

    <Map />

  </div>
}

export default Home;
