import React from 'react';
import { Helmet } from 'react-helmet';

const Teethwhitening = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Teeth Whitening Treatment In Miranda | Dr Olga Palma</title>
      <meta name="description" content="Teeth whitening treatments are absolutely safe and do not harm the tooth. Want to brighten your smile in Miranda? Book an appointment now!" />
      <link rel="alternate" href="https://drolgap.com.au/teeth-whitening" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au/teeth-whitening" />

    </Helmet>

     <h1>Teeth Whitening Miranda</h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article> 
		<p>Everyone likes to have healthier and whiter teeth. It allows us to smile confidently at the office, gatherings, events and also at the dining table. But many of us can not pose a great smile due to discoloration or stains on our teeth. So, is there any way to tackle this issue? Sure there is. Nowadays, people visit a dentist and opt for professional teeth whitening. Not only do you get whiter teeth but they also remove decay or other infections in your teeth. So, if you are looking for teeth whitening options in Miranda, you have come to the right place. </p>
<p>Due to individual circumstances, teeth whitening can be more dramatic in some people than others and can be influenced by the current colour of your teeth and pre-existing conditions such as decay, existing restorations, teeth with root canal treatment, etc.</p>
<p>The most common side-effect of teeth whitening is increased sensitivity in the teeth and gums.</p>
<p>A dental assessment will be performed to help ensure that your teeth and gums are in the right condition to proceed with the whitening process, and to let you know if teeth whitening is suitable for you.</p>
<p>At our dental clinic, you will find all kinds of dental treatments at affordable rates. Over the years, countless people have come to us for comprehensive teeth whitening solutions. Everyone of them left with a broad smile on their face. Therefore, you should also come down to our clinic in Miranda.</p>
<h2><strong>Comprehensive Teeth Whitening Solutions</strong></h2>
<p>First of all, we will do a complete dental assessment when you visit us for teeth whitening. Following this assessment and depending upon its results, we will move forward. </p>
<h2><strong>We currently offer two different approaches to teeth whitening:</strong></h2>
<p><strong>Take-home teeth whitening kits:</strong> Take-home whitening gel is a professional teeth whitener only available through a licensed dental professional. Using custom trays your dentist makes to suit you specifically.</p>
<p><strong>In-chair teeth whitening:</strong> This is a non-invasive dental procedure which used advanced technology to whiten your smile in a single visit. It can be a good option for patients on the go or those with a special event coming such as a wedding or birthday.</p>
<p>We have always urged our patients to only use medically approved gel and trays for their at-home teeth whitening procedures. Otherwise, you may experience a few side effects such as increased sensitivity in your teeth and gums. Similarly, we have experts at our clinic for your in-chair teeth whitening procedure. And therefore, you will face no complications whatsoever.&nbsp;</p>
<h2><strong>Affordable Teeth Whitening Services</strong></h2>
<p>No one likes to withdraw a huge pile of cash just to pay their dentist and at Dr. Olga Palma, we understand this sentiment very well. Therefore, you will only see reasonable price tags for our dental care treatments including teeth whitening. Furthermore, you can also use AfterPay services to pay our fees.</p>
</article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="teeth whitening miranda" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568563978/website/services/Depositphotos_14351475_ds-min_nfhz0i.jpg" />
      </div>
    </div>
  </div>
}



export default Teethwhitening;
