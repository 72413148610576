import React from "react";
import { Helmet } from "react-helmet";

const Rootcanaltreatmentsutherlandshire = () => {
  return (
    <div className="px-2">
      <Helmet>
        <html lang="en" />
        <title>Root Canal Treatment in Sutherland Shire</title>
        <meta
          name="description"
          content="Experiencing severe dental pain or prolonged sensitivity? Visit Dr Olga Palma in Sutherland Shire for a consultation for root canal treatment. Book today!"
        />
        <link
          rel="alternate"
          href="https://drolgap.com.au/root-canal-treatment-sutherland-shire"
          hrefLang="en-AU"
        />
        <link
          rel="canonical"
          href="https://drolgap.com.au/root-canal-treatment-sutherland-shire"
        />
      </Helmet>

      <h1>Dental Crown & Bridge Services In Sutherland Shire </h1>
      <div className="horizontal-line"></div>
      <div className="flex -mx-2">
        <div className="w-1/2 px-2">
          <article>
            <p>
              Almost a third of the adult Australian population suffers from
              tooth decay. When tooth decay is left untreated, it can lead to
              various dental problems. Problems such as toothache, infection and
              even tooth loss. One of the alternatives to treat this is root
              canal treatment. So, where do you find the best root canal
              treatment in Sutherland Shire?&nbsp;
            </p>
            <p>
              Dr. Olga Palma has tremendous experience and expertise in cosmetic
              dentistry and orthodontics. With help of modern equipment, she has
              performed many root canal treatments in and around Sydney
              including Sutherland Shire. So, come down to our clinic for an
              excellent root canal treatment at the most affordable prices in
              Sutherland Shire.&nbsp;
            </p>
            <h2>
              <strong>Our First-class Approach</strong>
            </h2>
            <p>
              Dr Olga Palma is an expert when it comes to root canal treatment
              (endodontic treatment). She uses the latest approaches of this
              treatment to ensure a flawless result at the end.&nbsp;
            </p>
            <p>
              Many year ago, the root canal treatment was a very painful
              experience. However, due to advances in technology, we are capable
              of eliminating this pain to a great extent. Thus, you will
              experience a pain-free root canal treatment in Sutherland Shire
              with Dr. Olga Palma.
            </p>
            <p>
              In case the infection is too severe, the standard root canal
              treatment will not work. In such cases, we will either recommend
              another dental treatment or will refer you to a specialist
              endodontist.
            </p>
            <h2>
              <strong>AfterPay</strong>
            </h2>
            <p>
              No one likes to spend an extraordinary amount for their root canal
              treatment. But it is also true that dental care in Australia is
              not cheap. But Dr. Olga Palma&rsquo;s clinic offers the most
              reasonable price for their treatments including root canal
              treatment. Furthermore, we also accept AfterPay for our
              treatments. So, you can use AfterPay when you have to get a root
              canal treatment at Dr. Olga Palma.&nbsp;
            </p>
            <p>To book an appointment, visit our website immediately.</p>
          </article>
        </div>
        <div className="w-1/2 px-2">
          <img
            alt="root canal treatment sutherland shire"
            src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568564040/website/services/RX01-min_npo0gh.jpg"
          />
        </div>
      </div>
    </div>
  );
};

export default Rootcanaltreatmentsutherlandshire;
