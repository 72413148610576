import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from 'hoc/Layout/Navbar/Navbar';

const Header = () => {
  return <header>

    <div className="lg:flex items-stretch mb-12">
      <div className="w-auto p-4 lg:p-0">
        <Link to="/">
          <img alt="Dr Olga Palma" src="https://res.cloudinary.com/drolgap/image/upload/v1571217805/website/logo/dr-olga-palma-logo_bbu1ne.png" />
        </Link>
      </div>
      <div className="flex-1 self-end">
        <div className="lg:flex order-first md:order-last mb-6 lg:mb-20">
          <div className="flex-grow-0 lg:flex-1">            
          </div>
          <div className="w-auto mx-4 lg:mx-0">
            <p className="block mb-2 lg:mb-4 text-center text-grey-500"><i className="far fa-calendar-alt mr-4"></i>Attention by appointment</p>
            <a className="block bg-teal-700 hover:bg-blue-700 text-center text-lg md:rounded text-white py-4 px-6" href="https://www.centaurportal.com/d4w/org-2568/fsearchMobileFriendly?practiceID=2868" rel="noopener noreferrer" target="_blank">Book an appointment</a>
          </div>
        </div>

        <Navbar />
      </div>
    </div>
  </header>
}

export default Header;