import React from 'react';
import { Route } from 'react-router-dom';

import { routes } from 'routes/routes';

import Header from 'hoc/Layout/Header/Header';

const Public = () => {
  return <div className="container mx-auto overflow-hidden mt-8">
    <Header />
    {routes.map(route => {
      return <Route
        key={route.id}
        path={route.path}
        exact={route.exact}
        component={route.component}
      />
    })}
  </div>
}

export default Public;