import './Servicearea.css';
import React from 'react';
import { Helmet } from 'react-helmet';

const Servicearea = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Service Area | Cosmetic Dentures Sutherland Shire</title>
      <meta name="description" content="Service Area" />
      <link rel="alternate" href="https://drolgap.com.au/service-area" hrefLang="en-AU" />
      <link rel="canonical" href="https://drolgap.com.au/service-area" />
    </Helmet>

    <h1>Service Area </h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div>
        <article>
          <div>

            <div className="row">

              <h2>Dentist</h2>
              <div className="service-area">
                <a href="/dentist-cronulla">Cronulla</a>
              </div>

              <div className="service-area">
                <a href="/dentist-woollahra">Woollahra</a>
              </div>

              <div className="service-area">
                <a href="/dentist-sutherland-shire">Sutherland Shire</a>
              </div>
            </div>



            <div className="row">
              <h2>Emergency Dentist</h2>
              <div className="service-area">
                <a href="/emergency-dentist-sutherland-shire">Sutherland Shire</a>
              </div>

              <div className="service-area">
                <a href="/emergency-dentist-caringbah">Caringbah</a>
              </div>
            </div>


            <div className="row">
              <h2>Crowns and Bridges</h2>
              <div className="service-area">
                <a href="/crowns-and-bridges-sutherland-shire">Sutherland Shire</a>
              </div>
            </div>



            <div className="row">
              <h2>Root Canal Treatment</h2>
              <div className="service-area">
                <a href="/root-canal-treatment-sutherland-shire">Sutherland Shire</a>
              </div>
            </div>


            <div className="row">
              <h2>Teeth Whitening</h2>
              <div className="service-area">
                <a href="/teeth-whitening-sutherland-shire">Sutherland Shire</a>
              </div>
              <div className="service-area">
                <a href="/teeth-whitening-caringbah">Caringbah</a>
              </div>

              <div className="service-area">
                <a href="/teeth-whitening-menai">Menai</a>
              </div>
            </div>


            <div className="row">
              <h2>Dentures</h2>
              <div className="service-area">
                <a href="/dentures-sutherland-shire">Sutherland Shire</a>
              </div>
            </div>


            <div className="row">
              <h2>Cosmetic Dentist</h2>
              <div className="service-area">
                <a href="/cosmetic-dentist-sutherland-shire">Sutherland Shire</a>
              </div>
            </div>

          </div>
        </article>
      </div>

    </div>
  </div>
}



export default Servicearea;
