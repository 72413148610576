import React from 'react';
import { Helmet } from 'react-helmet';

const Dentistsutherlandshire = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Dentist Sutherland Shire | Dental Clinic Sutherland Shire</title>
      <meta name="description" content="Known for our gentle approach and precision, Dr. Olga Palma dental clinic provide dental care services in Sutherland Shire. Visit us for more information." />
      <link rel="alternate" href="https://drolgap.com.au/dentist-sutherland-shire" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au/dentist-sutherland-shire" />

    </Helmet>

     <h1>Dentist In Sutherland Shire </h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
		
		<p>There are many reasons why you may have to visit a dentist nearby. Reasons such as broken teeth, misaligned teeth, toothache or other emergency treatments. But you should always visit the best dental clinic. Or else, you may end up aggravating your dental problems later. Dr. Olga Palma&rsquo;s dental clinic offers the best services and treatment in Sutherland Shire.</p>
<p>Dr. Olga Palma has loads of experience behind her to deal with every kind of dental problem. You will find the most advanced medical equipment at her dental clinic. Similarly, she has a brilliant team to assist her while she performs major dental treatments on her patients. So, you can definitely visit her dental clinic and get the best treatment in Sutherland Shire.</p>
<h2><strong>Why Come to Us?</strong></h2>
<p>But why should you come to our clinic in Kingsway, Miranda? Especially when there are lots of other dental clinics in Sutherland Shire. That is why we have showcased our credentials below. So, you can go through them before making the decision.&nbsp;</p>
<h2><strong>World-class Dental Services</strong></h2>
<p>Dr. Olga Palma&rsquo;s dental clinic provides a host of dental treatments for our patients in Sutherland Shire. Over the years, we have received many patients with different kinds of cosmetic dentistry and orthodontic problems. We have always delivered the best treatment to these patients at the most affordable price in Australia. Here are a few of those treatments available at our clinic.&nbsp;</p>
<ul>
<li>Dental Veneers:</li>
<li>Dental Implants</li>
<li>Dentures</li>
<li>Teeth Extraction</li>
<li>Sleep Apnoea and Snoring</li>
<li>Root Canal Treatment</li>
<li>Crowns and Bridges</li>
<li>Amalgam Removal</li>
<li>Chronic Pain</li>
<li>Clear Aligners for Teeth Straightening
</li>
</ul>
<p>Apart from the treatments listed above, we also specialise in child&rsquo;s dental care in Sutherland Shire. For this, we support the Medicare (CDBS) scheme of the Australian Government. If you don&rsquo;t know its benefits for your children&rsquo;s dental care then read the following section.&nbsp;</p>
<p><strong>Medicare (CDBS): </strong>With this scheme, your child is entitled to receive dental care benefits over a period of 2 years. You can bring your child into our clinic for regular dental check-up. Similarly, if there are other dental procedures to be done then those treatments will be covered by CDBS as well. Here are the immediate benefits of the Medicare Child Dental Benefits Schedule scheme.</p>
<ul>
<li>A comprehensive dental assessment of your child every 6 months.&nbsp;</li>
<li>X-rays if necessary.</li>
<li>We will provide complete oral hygiene for your children.</li>
<li>Fissure sealants</li>
<li>Quick tooth extractions</li>
<li>Partial dentures</li>
</ul>
<p>Due to so many child dental care benefits, you should book your appointment with Dr. Olga Palma today!&nbsp;</p>
<p><strong>AfterPay: </strong>We know how costly a few of the dental treatments could be. That is why we allow our patients to pay through AfterPay payment gateway. in case you are wondering how it helps you make the payment then do read the following pointers.</p>
<ul>
<li>AfterPay divides your total payment into four equal payments to be paid after every two weeks.&nbsp;</li>
<li>If you are living in Australia and are over the age of 18 years, you can get this service with an Australian credit/debit card.&nbsp;</li>
<li>However, you can only use AfterPay for dental treatments up to $1,500.&nbsp;</li>
<li>Use the AfterPay app on your mobile device to get started. Also, read other important Terms and Conditions listed on our website before using AfterPay.&nbsp;</li>
</ul>
<p>Be it dental veneers, dentures, root canal treatment or teeth straightening with Clear Aligners, you will get the best service at our clinic. So, head over to our dental clinic in Miranda. Book your appointment with the finest dentist in Sutherland Shire, visit our website immediately.</p>
		
		   </article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="dentist sutherland shire" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1568564023/website/services/Depositphotos_24727361_ds-min_b2ux42.jpg" />
      </div>
    </div>
  </div>
}



export default Dentistsutherlandshire;
