import React from 'react';
import { Helmet } from 'react-helmet';

const Mouthguards = () => {
  return <div className="px-2">

    <Helmet>
      <html lang="en" />
      <title>Sports Mouthguards In Miranda | Dr Olga Palma</title>
      <meta name="description" content="Use a Sports mouthguard provided by our dentist Dr. Olga Palma to protect your teeth during competitive sports. Visit our clinic in Miranda for a custom fit." />
      <link rel="alternate" href="https://drolgap.com.au/mouthguards" hrefLang="en-AU"/>
      <link rel="canonical" href="https://drolgap.com.au/mouthguards" />

    </Helmet>

     <h1>Sports Mouth Guards In Miranda</h1>
    <div className="horizontal-line"></div>
    <div className="flex -mx-2">
      <div className="w-1/2 px-2">
        <article>
		<p>In sports, there is a need to protect your head and your mouth. Otherwise, you may experience broken teeth, concussion or even some other permanent damages to your head. Therefore, it is important for every athlete to wear sports mouthguards before entering the field. But there are different types of mouthguards available in the market. So, where should you go for the most solid set of sports mouthguards? </p>
<p>At Dr. Olga Palma’s dental clinic, you will get the most comfortable, safe and affordable sports mouthguards.</p>
<p>At our clinic, we have the latest technology to build sports mouthguards for every individual athlete. So, why not get your next sports mouthguard at our clinic in Miranda? </p>

<h2><strong>Why Buy From Us</strong>?</h2>
<p>Now, you may believe that a mouthguard bought from a store will do the job, But that’s not the case. Usually, the mouthguards which are purchased from local stores don’t fill well. They can be poorly fitting and cause breathing and speech impediments. Therefore, you should always get a customised sports mouthguard from a dentist.</p>

<h2><strong>Our Sports Mouthguards</strong>&nbsp;</h2>
<p>Over the years, we have provided a huge number of sports mouthguards in Miranda. Our mouthguards are custom made for every athlete or individual who comes to our clinic. This is how we build custom sports mouthguards at our clinic.&nbsp;</p>
<ul>
<li>First, we take impressions of your teeth. This procedure is very quick. During this session, you can also tell us what colour mouthguard you want.</li>
</ul>
<ul>
<li>Next, we will start working on your mouthguard in our laboratory.&nbsp;</li>
</ul>
<ul>
<li>&nbsp;Then, we will call you to try the newly made sports mouthguard for the first time.</li>
</ul>


<h2><strong>Benefits Of Our Mouthguards</strong></h2>
<p>There are lots of benefits associated with getting your sports mouthguards at Dr. Olga Palma dental clinic. We would love to showcase them below. </p>
<ul>
<li>You can focus on playing your respective sports without worrying about the fitting of the mouthguard.</li>
</ul>
<ul>
<li>Our mouthguards protect you from cuts, bruises and tooth fractures. </li>
</ul>
<ul>
<li>We supply sports mouthguards for the most affordable price.</li>
</ul>
<h2><strong>Flexible Payment Options</strong></h2>
<p>As mentioned earlier, we have the most reasonable prices for our sports mouthguards. On top of this, you get the option to pay for our services through AfterPay.</p>

        </article>
      </div>
      <div className="w-1/2 px-2">
        <img alt="sports mouthguards miranda" src="https://res.cloudinary.com/drolgap/image/upload/q_auto/v1611039056/website/services/mouthguard_xzgyqj.jpg" />
      </div>
    </div>
  </div>
}



export default Mouthguards;
